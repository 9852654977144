<template>
	<div class="pageContainer">
		<a-row class="personCenter">
			<a-row class="titleRow">
				<span class="longString"></span>
				个人信息修改
			</a-row>
			<a-row class="headerRow">
				<a-col :span="2" class="header">头像：</a-col>
				<a-col :span="3">
					<!-- <img :src="src" class="img" /> -->
					<a-avatar icon="user" class="img" :size="100" :src="userInfo.avatarUrl"></a-avatar>
					<a-button class="button" @click="changeHeaderImg()">更换头像</a-button>
				</a-col>
			</a-row>
			<a-row class="row">
				<a-col :span="2" class="header">用户昵称：</a-col>
				<a-col :span="3">
					<a-input class="formInput" v-model="userInfo.name" placeholder="请输入昵称"></a-input>
				</a-col>
			</a-row>
			<a-row class="row">
				<a-col :span="2" class="header">手机号码：</a-col>
				<a-col :span="3">
					<a-input readOnly class="formInput" v-model="userInfo.phone" placeholder="请输入您的手机号码"></a-input>
				</a-col>
			</a-row>
			<a-row class="row">
				<a-col :span="2" class="header">注册时间：</a-col>
				<a-col :span="3">
					<a-input readOnly class="formInput" v-model="userInfo.addTime"></a-input>
				</a-col>
			</a-row>
			<a-row class="row">
				<a-col :span="2" class="header">会员等级：</a-col>
				<a-col :span="3" class="changePass">
					<a @click="showVip(userInfo)" v-if="vipList.length > 0 && $moment(now).format('YYYY-MM-DD HH:mm:ss') <= vipTime">
						{{ vipList.length > 0 ? (vipList[0].level == 1 ? '月度会员' : vipList[0].level == 2 ? '季度会员' : vipList[0].level == 3 ? '半年会员' : vipList[0].level == 4 ? '年度会员' : '普通用户') : '普通用户' }}
					</a>
					<a-divider v-if="payVipInfo.length > 0 && $moment(now).format('YYYY-MM-DD HH:mm:ss') <= payVipTime && vipList.length > 0 && $moment(now).format('YYYY-MM-DD HH:mm:ss') <= vipTime" type="vertical" style="margin: 0 5px;"></a-divider>
					<a @click="showVip(userInfo)" v-if="payVipInfo.length > 0 && $moment(now).format('YYYY-MM-DD HH:mm:ss') <= payVipTime">{{ payVipInfo[0].orderType == 2 ? '普通会员' : '普通用户' }}</a>
					<a-divider
						v-if="comboInfo.length > 0 && $moment(now).format('YYYY-MM-DD HH:mm:ss') <= comboTime && vipList.length > 0 && $moment(now).format('YYYY-MM-DD HH:mm:ss') <= vipTime && (payVipInfo.length == 0 || $moment(now).format('YYYY-MM-DD HH:mm:ss') > payVipTime)"
						type="vertical"
						style="margin: 0 5px;"
					></a-divider>
					<a-divider v-if="comboInfo.length > 0 && $moment(now).format('YYYY-MM-DD HH:mm:ss') <= comboTime && payVipInfo.length > 0 && $moment(now).format('YYYY-MM-DD HH:mm:ss') <= payVipTime" type="vertical" style="margin: 0 5px;"></a-divider>
					<a
						@click="showVip(userInfo)"
						v-if="comboInfo.length > 0 && $moment(now).format('YYYY-MM-DD HH:mm:ss') <= comboTime"
						:style="vipList.length > 0 && $moment(now).format('YYYY-MM-DD HH:mm:ss') <= vipTime && $moment(now).format('YYYY-MM-DD HH:mm:ss') <= payVipTime && payVipInfo.length > 0 ? 'display: block; padding-top: 5px;' : ''"
					>
						{{ comboInfo[0].orderType == 1 ? '套餐会员' : '普通用户' }}
					</a>
					<a @click="showVip(userInfo)" v-if="(noVipDetal && userInfo.isVip == 0) || $moment(now).format('YYYY-MM-DD HH:mm:ss') > tmporaryMember">普通用户{{ $moment(now).format('YYYY-MM-DD HH:mm:ss') > tmporaryMember ? '(会员过期)' : '' }}</a>
				</a-col>
			</a-row>
			<a-row class="row" v-if="(!noVipDetal && userInfo.isVip == 1) || tmporaryMember >= $moment(now).format('YYYY-MM-DD HH:mm:ss')">
				<a-col :span="2" class="header">会员到期：</a-col>
				<a-col :span="3" class="changePass">
					{{ tmporaryMember }}
				</a-col>
			</a-row>
			<template v-if="userInfo.isVip === 2">
				<a-row class="row">
					<a-col :span="3" class="header">剩余智能检测次数：</a-col>
					<a-col :span="3" class="changePass">{{ freeIntelligence }} 次</a-col>
				</a-row>
				<a-row class="row">
					<a-col :span="3" class="header">剩余人工检测次数：</a-col>
					<a-col :span="3" class="changePass">{{ freeArtificial }} 次</a-col>
				</a-row>
			</template>
			<a-row class="row" v-if="userInfo.userType == 2">
				<a-col :span="2" class="header">
					免费期
					<a-tooltip placement="top">
						<template slot="title">
							<span>政府账号智能审读免费期</span>
						</template>
						<a-icon type="question-circle" />
						：
					</a-tooltip>
				</a-col>
				<a-col :span="3">
					<a-input readOnly class="formInput" v-model="userInfo.expirationTime"></a-input>
				</a-col>
			</a-row>
			<a-row class="row">
				<a-col :span="2" class="header">密码修改：</a-col>
				<a-col :span="3" class="changePass">
					<a @click="changePass()">修改密码</a>
				</a-col>
			</a-row>
			<a-row class="row">
				<a-col :span="2" class="header">实名认证：</a-col>
				<a-col :span="3" class="examine">
					<a-button size="small" v-if="examineState == 0 && op == 'add'" type="primary" @click="verifiedAdd">
						立即实名
					</a-button>
					<template v-if="examineState == 0 && op == 'updata'">
						<span class="examineFaile">认证未通过</span>
						<a-button size="small" class="queryBtn" type="primary" @click="verifiedAdd">查看</a-button>
					</template>
					<template v-if="examineState == 2">
						<span class="examineFaile">认证未通过</span>
						<a-button size="small" class="queryBtn" type="primary" @click="verifiedAdd">查看</a-button>
					</template>
					<template v-if="examineState == 3">
						<span class="examineing">认证中</span>
						<a-button size="small" class="queryBtn" type="primary" @click="verifiedAdd">查看</a-button>
					</template>
					<template v-if="examineState == 1">
						<a class="examineSuccess">已认证</a>
					</template>
				</a-col>
			</a-row>
			<a-divider />
			<a-row class="footer">
				<a-button class="back" @click="back" :disabled="loading">返回</a-button>
				<a-button type="primary" class="submit" :loading="loading" @click="submit()">确认保存</a-button>
			</a-row>
		</a-row>
		<UpLoad :visible="visible" :closeModal="closeModal" @filePath="filePath" />
		<ChangePassword :passVisible="passVisible" :closePassModal="closePassModal" />
		<VerifiedAddModal :userAuthenticationFail="userAuthenticationFail" :changeExpirationTime="changeExpirationTime" :op="op" :examineState="examineState" :verifiedAddModalShow="verifiedAddModalShow" :closeVerifiedAddModal="closeVerifiedAddModal" />
		<VipDetail ref="detailForm"></VipDetail>
	</div>
</template>
<script>
import UpLoad from './components/UpLoad';
import ChangePassword from './components/ChangePassword';
import VerifiedAddModal from './components/VerifiedAddModal';
import VipDetail from '../../components/vipDetail';
import moment from 'moment';

export default {
	components: {
		UpLoad,
		ChangePassword,
		VerifiedAddModal,
		VipDetail,
	},
	data() {
		return {
			now: Date.now(),
			freeIntelligence: 0,
			freeArtificial: 0,
			src: 'https://resources.xbov.cn/img/hxznggfwpt/personal_center.png',
			userName: null,
			phone: null,
			time: null,
			visible: false,
			loading: false,
			passVisible: false,
			examineState: -1,
			userInfo: {},
			verifiedAddModalShow: false,
			userAuthenticationFail: '',
			op: 'add',
			tmporaryMember: null,
			payVipInfo: [],
			comboInfo: [],
			vipList: [],
			comboTime: null,
			payVipTime: null,
			vipTime: null,
			noVipDetal: true,
		};
	},
	created() {
		this.userInfo = this.$store.getters.userInfo;
		this.userInfo.addTime = this.$moment(this.userInfo.addTime).format('YYYY-MM-DD HH:mm:ss');
		this.userInfo.expirationTime = this.$moment(this.userInfo.expirationTime).format('YYYY-MM-DD HH:mm:ss');
		this.userInfo.freeReviewTime = this.$moment(this.userInfo.freeReviewTime).format('YYYY-MM-DD HH:mm:ss');
		this.userAuthenticationQuery();
		this.getUserInfo();
		this.getPayVipDetail();
		this.getComboVipDetail();
		this.getVipOrderList();
	},
	methods: {
		contrastTime() {
			if (this.comboInfo.length > 0) {
				if (this.payVipInfo.length > 0) {
					if (this.comboTime >= this.payVipTime) {
						if (this.vipList.length > 0) {
							if (this.comboTime >= this.vipTime) {
								this.tmporaryMember = this.comboTime;
							} else {
								this.tmporaryMember = this.vipTime;
							}
						} else {
							this.tmporaryMember = this.comboTime;
						}
					} else if (this.comboTime < this.payVipTime) {
						if (this.vipList.length > 0) {
							if (this.payVipTime >= this.vipTime) {
								this.tmporaryMember = this.payVipTime;
							} else {
								this.tmporaryMember = this.vipTime;
							}
						} else {
							this.tmporaryMember = this.payVipTime;
						}
					}
				} else if (this.vipList.length > 0) {
					if (this.comboTime >= this.vipTime) {
						this.tmporaryMember = this.comboTime;
					} else {
						this.tmporaryMember = this.vipTime;
					}
				} else {
					this.tmporaryMember = this.comboTime;
				}
			}
			if (this.payVipInfo.length > 0) {
				if (this.comboInfo.length > 0) {
					if (this.comboTime >= this.payVipTime) {
						if (this.vipList.length > 0) {
							if (this.comboTime >= this.vipTime) {
								this.tmporaryMember = this.comboTime;
							} else {
								this.tmporaryMember = this.vipTime;
							}
						} else {
							this.tmporaryMember = this.comboTime;
						}
					} else if (this.comboTime < this.payVipTime) {
						if (this.vipList.length > 0) {
							if (this.payVipTime >= this.vipTime) {
								this.tmporaryMember = this.payVipTime;
							} else {
								this.tmporaryMember = this.vipTime;
							}
						} else {
							this.tmporaryMember = this.payVipTime;
						}
					}
				} else if (this.vipList.length > 0) {
					if (this.payVipTime >= this.vipTime) {
						this.tmporaryMember = this.payVipTime;
					} else {
						this.tmporaryMember = this.vipTime;
					}
				} else {
					this.tmporaryMember = this.payVipTime;
				}
			}
			if (this.vipList.length > 0) {
				if (this.payVipInfo.length > 0) {
					if (this.vipTime >= this.payVipTime) {
						if (this.comboInfo.length > 0) {
							if (this.comboTime >= this.vipTime) {
								this.tmporaryMember = this.comboTime;
							} else {
								this.tmporaryMember = this.vipTime;
							}
						} else {
							this.tmporaryMember = this.vipTime;
						}
					} else if (this.vipTime < this.payVipTime) {
						if (this.comboInfo.length > 0) {
							if (this.comboTime >= this.payVipTime) {
								this.tmporaryMember = this.comboTime;
							} else {
								this.tmporaryMember = this.payVipTime;
							}
						} else {
							this.tmporaryMember = this.payVipTime;
						}
					}
				} else if (this.comboInfo.length > 0) {
					if (this.comboTime >= this.vipTime) {
						this.tmporaryMember = this.comboTime;
					} else {
						this.tmporaryMember = this.vipTime;
					}
				} else {
					this.tmporaryMember = this.vipTime;
				}
			}
		},
		showVip(data) {
			let dataList = {
				data,
			};
			if (this.payVipInfo.length > 0) {
				this.noVipDetal = false;
			} else if (this.comboInfo.length > 0) {
				this.noVipDetal = false;
			} else if (this.vipList.length > 0) {
				this.noVipDetal = false;
			} else {
				this.noVipDetal = true;
			}
			this.$refs.detailForm.getVipDetailInit(data, this.noVipDetal);
		},
		getVipOrderList() {
			const _this = this;
			let params = {
				userCode: _this.userInfo.userCode,
				state: 1,
			};
			this.$http
				.post('/viporder/queryList', params)
				.then(res => {
					_this.pageLoading = false;
					if (res.rescode == 200) {
						_this.vipList = res.data.dataList;
						if (res.data.dataList.length > 0) {
							this.noVipDetal = false;
							_this.contrastTime();
						}
					} else {
						_this.$message.error('查询会员信息失败');
						_this.pageLoading = false;
					}
				})
				.catch(err => {
					_this.pageLoading = false;
					_this.$message.error('查询用户失败');
				});
		},
		queryUserSetting(params) {
			const _this = this;
			_this.$http
				.post('/usersetting/queryList', params)
				.then(res => {
					if (res.rescode === 200) {
						let data = res.data.dataList[0];
						if (data.configKey === 'FreeIntelligence') {
							_this.freeIntelligence = data.configValue;
						} else if (data.configKey === 'FreeArtificial') {
							_this.freeArtificial = data.configValue;
						}
					} else {
						_this.$message.error('查询会员信息失败');
					}
				})
				.catch(err => {});
		},
		getUserInfo() {
			const _this = this;
			this.$http.get('/userconsumer/query?userCode=' + this.userInfo.userCode).then(res => {
				if (res.rescode == 200) {
					let userInfo = JSON.parse(JSON.stringify(res.data));
					userInfo.addTime = this.$moment(userInfo.addTime).format('YYYY-MM-DD HH:mm:ss');
					userInfo.expirationTime = this.$moment(userInfo.expirationTime).format('YYYY-MM-DD HH:mm:ss');
					_this.vipTime = this.$moment(res.data.freeReviewTime).format('YYYY-MM-DD HH:mm:ss');
					_this.userInfo = userInfo;
					_this.$store.dispatch('saveUserInfo', res.data);
					if (userInfo.isVip === 2) {
						// 查询剩余免费智能检测次数
						let params1 = {
							configKey: 'FreeIntelligence',
							userCode: userInfo.userCode,
						};
						this.queryUserSetting(params1);
						// 查询剩余免费人工检测次数
						let params2 = {
							configKey: 'FreeArtificial',
							userCode: userInfo.userCode,
						};
						this.queryUserSetting(params2);
					}
				} else {
					_this.$message.warning(res.msg);
				}
			});
		},
		getPayVipDetail() {
			const _this = this;
			let params = {
				userCode: _this.userInfo.userCode,
			};
			_this.$http
				.post('/vipOpen/findMemberInfo', params)
				.then(res => {
					_this.pageLoading = false;
					if (res.rescode == 200) {
						_this.payVipInfo = res.data;
						if (res.data.length > 0) {
							this.noVipDetal = false;
							_this.payVipTime = this.$moment(res.data[0].validityPeriod).format('YYYY-MM-DD HH:mm:ss');
							_this.contrastTime();
						}
					} else {
						_this.$message.error('查询会员信息失败');
					}
				})
				.catch(err => {
					_this.pageLoading = false;
					_this.$message.error('查询会员信息失败');
				});
		},
		getComboVipDetail() {
			const _this = this;
			let params = {
				userCode: _this.userInfo.userCode,
			};
			_this.$http
				.post('/vipOpen/findComboInfo', params)
				.then(res => {
					_this.pageLoading = false;
					if (res.rescode == 200) {
						_this.comboInfo = res.data;
						if (res.data.length > 0) {
							this.noVipDetal = false;
							_this.comboTime = this.$moment(res.data[0].validityPeriod).format('YYYY-MM-DD HH:mm:ss');
							_this.contrastTime();
						}
					} else {
						_this.pageLoading = false;
						_this.$message.error('查询会员信息失败');
					}
				})
				.catch(err => {
					_this.pageLoading = false;
					_this.$message.error('查询会员信息失败');
				});
		},
		changeExpirationTime() {
			this.op = 'updata';
			this.examineState = 0;
		},
		closeVerifiedAddModal() {
			this.verifiedAddModalShow = false;
			this.op = 'add';
			this.userAuthenticationQuery();
		},
		verifiedAdd() {
			this.verifiedAddModalShow = true;
		},
		userAuthenticationQuery() {
			const _this = this;
			_this.$http.get('/userauthentication/query?userCode=' + this.userInfo.userCode).then(res => {
				if (res.rescode == 200) {
					_this.examineState = res.data.examineState;
					if (res.data.examineState == 2) {
						_this.userAuthenticationFail = res.data.remark;
					}
				} else if (res.rescode == 201 && res.msg == '未找到') {
					_this.examineState = 0;
				}
			});
		},
		filePath(val) {
			this.userInfo.avatarUrl = val;
		},
		back() {
			this.$router.go(-1);
		},
		closeModal() {
			this.visible = false;
		},
		changeHeaderImg() {
			this.visible = true;
		},
		changePass() {
			this.passVisible = true;
		},
		closePassModal() {
			this.passVisible = false;
		},
		submit() {
			if (!this.userInfo.name || this.userInfo.name == '' || this.userInfo.name.length < 2 || this.userInfo.name.length > 8) {
				this.$Notification['warning']({
					message: '请输入2到8位的用户昵称！',
				});
				return;
			}
			this.loading = true;
			let params = {
				name: this.userInfo.name,
				avatarUrl: this.userInfo.avatarUrl,
				userCode: this.userInfo.userCode,
			};
			this.$http
				.post('/userconsumer/update', params)
				.then(res => {
					this.$message.success('修改成功');
					this.loading = false;
					this.$router.go(-1);
				})
				.catch(err => {
					console.log('err', err);
					this.loading = false;
				});
		},
	},
};
</script>
<style lang="less" scoped>
.pageContainer {
	.personCenter {
		background: #fff;
		padding: 16px;
		height: 100%;
	}

	.ant-divider {
		margin: 16px 0;
	}

	.titleRow {
		font-size: 20px;
		position: relative;
		margin-left: 16px;

		.longString {
			width: 3px;
			height: 25px;
			background-color: #2d7bf8;
			position: absolute;
			top: -3px;
			left: -10px;
		}
	}

	.headerRow {
		margin-top: 20px;
		margin-bottom: 70px;
		margin-left: 20px;
		font-size: 18px;

		.header {
			padding-top: 50px;
			text-align: center;
		}

		.img {
			width: 100px;
			height: 100px;
			border-radius: 50px;
			position: relative;
		}

		.button {
			position: absolute;
			bottom: -40px;
			left: 10px;
		}
	}

	.row {
		margin-top: 20px;
		margin-left: 20px;
		font-size: 18px;

		.header {
			padding-top: 5px;
			text-align: center;
		}

		.examine {
			padding-top: 5px;
		}

		.formInput {
			background-color: #f7f8fc;
			width: 220px;
		}

		.changePass {
			padding-top: 5px;
		}

		.queryBtn {
			margin-left: 8px;
		}

		.examineFaile {
			color: #f5222d;
		}

		.examineSuccess {
			color: #52c41a;
		}

		.examineing {
			color: #1890ff;
		}
	}

	.footer {
		margin-left: 50px;

		.back {
			width: 90px;
		}

		.submit {
			margin-left: 20px;
			width: 110px;
		}
	}
}
</style>
