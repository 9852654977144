<template>
	<a-modal :visible="verifiedAddModalShow" title="实名认证" @cancel="closeModal" :confirm-loading="loading" @ok="handleOk">
		<template slot="footer">
			<a-button key="back" @click="closeModal">
				取消
			</a-button>
			<a-button key="submit" type="primary" v-if="examineState == 0" :loading="loading" @click="handleOk">
				确定
			</a-button>
		</template>
		<a-spin :spinning="loading" v-if="examineState == 0">
			<a-alert class="alert" message="您的个人信息将会受到严格保护，仅用于实名认证" banner></a-alert>
			<a-form-model ref="Form" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
				<a-form-model-item label="姓名" prop="userName">
					<a-input v-model="form.userName" placeholder="请输入您的姓名" :maxLength="20" />
				</a-form-model-item>
				<a-form-model-item required label="身份证正面">
					<a-upload :action="resourcesApiHost" list-type="picture-card" :before-upload="beforeImgUpload" :file-list="idPositiveFileList" @preview="handlePreview" @change="handleImgChange1">
						<div v-if="idPositiveFileList.length < 1">
							<a-icon type="plus" />
							<div class="ant-upload-text">
								上传图片
							</div>
						</div>
					</a-upload>
				</a-form-model-item>
				<a-form-model-item required label="身份证背面">
					<a-upload :action="resourcesApiHost" list-type="picture-card" :before-upload="beforeImgUpload" :file-list="idBackFileList" @preview="handlePreview" @change="handleImgChange2">
						<div v-if="idBackFileList.length < 1">
							<a-icon type="plus" />
							<div class="ant-upload-text">
								上传图片
							</div>
						</div>
					</a-upload>
				</a-form-model-item>
			</a-form-model>
		</a-spin>
		<a-result v-if="examineState == 2" status="error" title="实名认证未通过!">
			<template #extra>
				<a-button key="console" type="primary" @click="updata">
					重新认证
				</a-button>
			</template>
			<div class="desc">
				<p style="text-align: center">拒绝原因：{{ userAuthenticationFail }}</p>
				<p style="text-align: center">如有疑问：请联系客服176-7316-1640</p>
			</div>
		</a-result>
		<a-result v-if="examineState == 3" title="实名认证审核中···">
			<div class="desc">
				<p style="text-align: center">实名认证审核需要1-3个工作日，请耐心等待···</p>
				<p style="text-align: center">如有疑问：请联系客服176-7316-1640</p>
			</div>
		</a-result>
		<Vviewer ref="viewer"></Vviewer>
	</a-modal>
</template>
<script>
import Vviewer from '../../../components/Vviewer.vue';
export default {
	props: ['verifiedAddModalShow', 'closeVerifiedAddModal', 'examineState', 'op', 'changeExpirationTime', 'userAuthenticationFail'],
	data() {
		const validatorUserName = (rule, value, callback) => {
			if (!value) {
				callback(new Error('请输入您的姓名!'));
			} else {
				if (!/^[\u4e00-\u9fa5]+$/gi.test(value)) {
					callback(new Error('只允许输入中文!'));
				} else {
					callback();
				}
			}
		};
		return {
			loading: false,
			labelCol: { span: 6 },
			wrapperCol: { span: 14 },
			form: {
				userName: '',
			},
			rules: {
				userName: [{ required: true, validator: validatorUserName, trigger: 'change' }],
			},
			idPositiveFileList: [],
			idBackFileList: [],
			resourcesApiHost: '',
			resourcesHost: '',
			userInfo: {},
		};
	},
	components: { Vviewer },
	watch: {
		verifiedAddModalShow(data) {
			if (data) {
				this.resourcesApiHost = process.env.VUE_APP_RESOURCES_API_HOST;
				this.resourcesHost = process.env.VUE_APP_RESOURCES_HOST;
				this.userInfo = this.$store.getters.userInfo;
			}
		},
		op(data) {
			if (data == 'updata') {
				this.userAuthenticationQuery();
			}
		},
	},
	methods: {
		updata() {
			this.changeExpirationTime();
		},
		userAuthenticationQuery() {
			const _this = this;
			_this.$http.get('/userauthentication/query?userCode=' + this.userInfo.userCode).then(res => {
				if (res.rescode == 200) {
					_this.form.userName = res.data.userName;
					_this.idPositiveFileList = [
						{
							status: 'done',
							name: 'image.png',
							uid: '-1',
							url: _this.resourcesHost + res.data.idPositive,
							response: {
								data: {
									filePath: res.data.idPositive,
								},
							},
						},
					];
					_this.idBackFileList = [
						{
							status: 'done',
							name: 'image.png',
							uid: '-1',
							url: _this.resourcesHost + res.data.idBack,
							response: {
								data: {
									filePath: res.data.idBack,
								},
							},
						},
					];
				} else if (res.rescode == 201) {
					_this.$message.error(res.msg);
				}
			});
		},
		handleOk() {
			const _this = this;
			this.$refs.Form.validate(valid => {
				if (valid) {
					let idBackFileList = [..._this.idBackFileList];
					let idPositiveFileList = [..._this.idPositiveFileList];
					if (idPositiveFileList.length == 0) {
						_this.$message.warning('请上传身份证正面照片！');
						return;
					}
					if (idBackFileList.length == 0) {
						_this.$message.warning('请上传身份证背面照片！');
						return;
					}
					let params = {
						userName: _this.form.userName,
						idPositive: idPositiveFileList[0].response.data.filePath,
						idBack: idBackFileList[0].response.data.filePath,
					};
					_this.loading = true;
					let url = '/userauthentication/add';
					if (_this.op == 'updata') {
						url = '/userauthentication/update';
						params.examineState = 3;
						params.userCode = _this.userInfo.userCode;
					}
					_this.$http.post(url, params).then(res => {
						_this.loading = false;
						if (res.rescode == 200) {
							_this.closeModal();
						} else if (res.rescode == 201) {
							_this.message.warning(res.msg);
						}
					});
				}
			});
		},
		beforeImgUpload(file) {
			return new Promise((resolve, reject) => {
				var testmsg = /^image\/(jpeg|png|jpg|bmp|gif)$/.test(file.type);
				const isLt20M = file.size / 1024 / 1024 <= 20; //图片大小不超过2MB
				if (!testmsg) {
					this.$message.error('仅支持图片!');
					return reject(false);
				}
				if (!isLt20M) {
					this.$message.error('上传图片大小不能超过20M!');
					return reject(false);
				}
				return resolve(true);
			});
		},
		async handlePreview(file) {
			this.$refs.viewer.show([
				{
					thumbnail: process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath,
					source: process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath,
				},
			]);
		},
		async handleImgChange1({ file, fileList }) {
			if (file.status == 'done') {
				fileList.map(item => {
					if (item.status == 'done') {
						if (item.response.data.coverImgUrl && item.response.data.coverImgUrl != '') {
							item.thumbUrl = process.env.VUE_APP_RESOURCES_HOST + item.response.data.coverImgUrl;
						}
					}
				});
			}
			this.idPositiveFileList = fileList;
		},
		async handleImgChange2({ file, fileList }) {
			if (file.status == 'done') {
				fileList.map(item => {
					if (item.status == 'done') {
						if (item.response.data.coverImgUrl && item.response.data.coverImgUrl != '') {
							item.thumbUrl = process.env.VUE_APP_RESOURCES_HOST + item.response.data.coverImgUrl;
						}
					}
				});
			}
			this.idBackFileList = fileList;
		},
		closeModal() {
			this.idPositiveFileList = [];
			this.idBackFileList = [];
			this.closeVerifiedAddModal();
		},
	},
};
</script>
<style lang="less" scoped>
.alert {
	margin-bottom: 16px;
}
.desc {
	p {
		margin-bottom: 16px;
	}
}
</style>
