<template>
    <a-modal class="illegalAdSubModal" title="修改密码" :maskClosable="false" okText="提交" cancelText="关闭" :visible="passVisible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
        <a-row class="row">
            <a-col :span="5" class="header">手机号码：</a-col>
            <a-col :span="10">
                <a-input class="formInput" readOnly v-model="phone" placeholder="请输入您的手机号码"> </a-input>
            </a-col>
        </a-row>
        <a-row class="row">
            <a-col :span="5" class="header">验证码：</a-col>
            <a-col :span="18">
                <a-input class="code" v-model="code" placeholder="请输入您的验证码"> </a-input>
                <a-button type="primary" class="getCode" :disabled="getSmscodeFlag" @click="getSmsCode">{{ smsCount }}{{ getSmscodeFlag ? "秒" : "" }}</a-button>
            </a-col>
        </a-row>
        <a-row class="row">
            <a-col :span="5" class="header">密码：</a-col>
            <a-col :span="10">
                <a-input-password class="formInput" v-model="password" placeholder="请输入您的密码"> </a-input-password>
            </a-col>
        </a-row>
        <a-row class="row">
            <a-col :span="5" class="header">确认密码：</a-col>
            <a-col :span="10">
                <a-input-password class="formInput" v-model="password2" placeholder="请再次输入您的密码"> </a-input-password>
            </a-col>
        </a-row>
    </a-modal>
</template>

<script>
export default {
    props: ["passVisible", "closePassModal"],
    data() {
        return {
            confirmLoading: false,
            phone: null,
            password: null,
            password2: null,
            code: null,
            smsCount: "获取验证码",
            getSmscodeFlag: false,
        };
    },
    created() {
        let userInfo = this.$store.getters.userInfo;
        this.phone = userInfo.phone;
    },
    methods: {
        getSmsCode() {
            const _this = this;
            if (!this.phone || this.phone == "") {
                _this.$message.warning("请先输入手机号");
            } else {
                _this.$http.get("/login/smsSendCode?phone=" + _this.phone + "&type=2").then((res) => {
                    _this.getSmscodeFlag = true;
                    _this.smsCount = 60;
                    let timer = setInterval(() => {
                        _this.smsCount -= 1;
                        if (_this.smsCount == 0) {
                            clearInterval(timer);
                            _this.smsCount = "获取验证码";
                            _this.getSmscodeFlag = false;
                        }
                    }, 1000);
                });
            }
        },
        handleCancel() {
            this.closePassModal();
        },
        handleOk() {
            if (!this.code) {
                this.$Notification["warning"]({
                    message: "请输入验证码",
                });
                return;
            }
            if (!this.password || !this.password2) {
                this.$Notification["warning"]({
                    message: "请输入密码",
                });
                return;
            }
            if (this.password && this.password2 && this.password != this.password2) {
                this.$Notification["warning"]({
                    message: "两次密码不一致请重新输入",
                });
                return;
            }
            this.confirmLoading = true;
            let params = {
                phone: this.phone,
                code: this.code,
                passWord: this.password,
            };
            this.$http
                .post("/usertoken/resetPassword", params)
                .then((res) => {
                    if (res.rescode == 200) {
                        this.closePassModal();
                        this.confirmLoading = false;
                        this.success();
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
                .catch((error) => {
                    // this.$message.error(res.msg);
                    console.log(error);
                });
        },
        success() {
            let _this = this;
            this.$success({
                title: "修改成功",
                // JSX support
                content: (
                    <div>
                        <p>密码修改成功，退出重新登录</p>
                    </div>
                ),
                onOk() {
                    _this.$store.dispatch("saveUserInfo", {});
                    _this.$store.dispatch("saveToken", null);
                    _this.$router.push({ name: "Login" });
                },
            });
        },
    },
};
</script>

<style lang="less" scoped>
.illegalAdSubModal {
    /deep/ .userPhoneSmsInput {
        width: 78%;
    }
    .row {
        margin-top: 10px;
        margin-left: 20px;
        font-size: 16px;
        .header {
            padding-top: 3px;
            text-align: center;
        }
        .formInput {
            width: 248px;
        }
        .code {
            width: 140px;
        }
    }
    .getCode {
        width: 30%;
        margin-left: 2%;
        border-radius: 3px;
    }
}
</style>
