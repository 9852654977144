<template>
	<a-modal :visible="showVipDetail" :width="1200" :title="detailTitle" @cancel="onClose" :footer="null">
		<a-spin :spinning="loading">
			<div class="box" v-if="noVipList">
				<a-row class="row">
					<a-col :span="24" class="alert">
						<a-icon class="icon" theme="twoTone" two-tone-color="#fa8c16" type="exclamation-circle" />
						该用户暂未购买会员，请
						<a href="#/openVip">点我前往</a>
						购买会员
					</a-col>
				</a-row>
			</div>
			<div class="box" v-else>
				<a-row class="row">
					<a-col :span="24" class="alert" style="text-align: center;">
						<a-icon class="icon" theme="twoTone" two-tone-color="#fa8c16" type="exclamation-circle" />
						如果会员过期，次数将以作废处理。
					</a-col>
				</a-row>
			</div>
			<div v-if="!noVipList" class="box" :scroll="{ y: divHeight }">
				<a-row class="row vipPrice">
					<a-col :span="24">
						<template v-if="vipList.length > 0">
							<a-divider orientation="left">
								会员信息
							</a-divider>
							<a-descriptions>
								<a-descriptions-item label="开通时间">
									{{ vipList.length > 0 ? moment(vipList[0].addTime) : '--' }}
								</a-descriptions-item>
								<a-descriptions-item label="到期时间">
									{{ moment(userInfo.freeReviewTime) }}
								</a-descriptions-item>
								<a-descriptions-item label="会员类型">
									<a-tag color="#faad14" v-if="vipList.length > 0 && moment(userInfo.freeReviewTime) >= moment(now)">
										{{ vipList.length > 0 ? (vipList[0].level == 1 ? '月度会员' : vipList[0].level == 2 ? '季度会员' : vipList[0].level == 3 ? '半年会员' : vipList[0].level == 4 ? '年度会员' : '--') : '--' }}
									</a-tag>
									<a-tag color="gray" v-if="moment(userInfo.freeReviewTime) < moment(now)">会员过期</a-tag>
								</a-descriptions-item>
								<a-descriptions-item label="会员支付记录" :span="3">
									<a-table key="flowId" :columns="columns" size="small" :loading="pageLoading" :data-source="vipList" style="marginTop:8px">
										<a slot="userCode" slot-scope="text" @click="queryUserInfo(text)">{{ text }}</a>
										<template slot="state" slot-scope="text">
											<a-tag :color="text == 0 ? '#fa8c16' : text == 1 ? '#52c41a' : '#f5222d'">{{ text == 0 ? '支付取消' : text == 1 ? '支付成功' : text == 2 ? '支付失败' : '--' }}</a-tag>
										</template>
									</a-table>
								</a-descriptions-item>
							</a-descriptions>
						</template>
						<template v-if="payVipInfo.length > 0">
							<a-divider orientation="left">
								普通会员信息
							</a-divider>
							<a-table rowKey="openTime" :columns="ordinaryVips" size="small" :loading="pageLoading" :data-source="payVipInfo" style="marginTop:8px" :pagination="pagination" @change="tableChange" height="250" :scroll="{ y: tableHeight }">
								<a-table-column prop="validityPeriod" label="到期时间"></a-table-column>
							</a-table>
						</template>
						<template v-if="comboInfo.length > 0">
							<a-divider orientation="left">
								套餐会员信息
							</a-divider>
							<a-table rowKey="openTime" :columns="comboColumns" size="small" :loading="pageLoading" :data-source="comboInfo" style="marginTop:8px" :pagination="pagination" @change="tableChange" :scroll="{ y: tableHeight }" height="250">
								<a-table-column prop="validityPeriod" label="到期时间"></a-table-column>
							</a-table>
						</template>
					</a-col>
				</a-row>
				<a-row class="row">
					<a-col :span="24" class="submitBtn">
						<a-button type="primary" @click="detailSubmit">确定</a-button>
					</a-col>
				</a-row>
			</div>
		</a-spin>
	</a-modal>
</template>

<script>
import moment from 'moment';

export default {
	data() {
		return {
			now: Date.now(),
			showVipDetail: false,
			noVipList: false,
			loading: true,
			pageLoading: false,
			tableHeight: 280,
			divHeight: 700,
			freeArtificial: 0,
			freeIntelligence: 0,
			detailTitle: '查看会员详情',
			userInfo: {},
			payVipInfo: [],
			comboInfo: [],
			currentTimeStamp: this.$moment().format('x'),
			vipList: [],
			pagination: {
				total: 0,
				current: 0,
				defaultPageSize: 10,
				showTotal: (total, range) => `当前第 ${range[0]}-${range[1]} 条 共计 ${total} 条`,
				showSizeChanger: true,
				pageSizeOptions: ['5', '10', '15', '20'],
				onShowSizeChange: (current, pageSize) => {
					this.pageSize = pageSize;
				},
			},
			ordinaryVips: [
				{
					title: '免费智能检测次数',
					dataIndex: 'intelligentMonitoringNumber',
					key: 'intelligentMonitoringNumber',
					customRender(text, record) {
						return text === -1 ? (text = '无限次') : text;
					},
				},
				{
					title: '免费人工检测次数',
					dataIndex: 'manualMonitoringNumber',
					key: 'manualMonitoringNumber',
					customRender(text, record) {
						return text === -1 ? (text = '无限次') : text;
					},
				},
				{
					title: '免费合同检测次数',
					dataIndex: 'contractReviewNumber',
					key: 'contractReviewNumber',
					customRender(text, record) {
						return text === -1 ? (text = '无限次') : text;
					},
				},
				{
					title: '会员价格',
					dataIndex: 'totalAmount',
					key: 'totalAmount',
					customRender(text, record) {
						return (text / 1000).toFixed(2);
					},
				},
				{
					title: '开通时间',
					dataIndex: 'openTime',
					key: 'openTime',
					customRender(text, record) {
						if (text) {
							return moment(text).format('YYYY-MM-DD HH:mm:ss');
						}
						return '--';
					},
				},
				{
					title: '到期时间',
					dataIndex: 'validityPeriod',
					key: 'validityPeriod',
					customRender(text, record) {
						if (text) {
							return moment(text).format('YYYY-MM-DD HH:mm:ss');
						}
						return '--';
					},
				},
			],
			comboColumns: [
				{
					title: '套餐名称',
					dataIndex: 'comboName',
					key: 'comboName',
					scopedSlots: { customRender: 'comboName' },
				},
				{
					title: '免费智能检测次数',
					dataIndex: 'intelligentMonitoringNumber',
					key: 'intelligentMonitoringNumber',
					customRender(text, record) {
						return text === -1 ? (text = '无限次') : text;
					},
				},
				{
					title: '免费人工检测次数',
					dataIndex: 'manualMonitoringNumber',
					key: 'manualMonitoringNumber',
					customRender(text, record) {
						return text === -1 ? (text = '无限次') : text;
					},
				},
				{
					title: '免费合同检测次数',
					dataIndex: 'contractReviewNumber',
					key: 'contractReviewNumber',
					customRender(text, record) {
						return text === -1 ? (text = '无限次') : text;
					},
				},
				{
					title: '套餐价格',
					dataIndex: 'totalAmount',
					key: 'totalAmount',
					customRender(text, record) {
						return (text / 1000).toFixed(2);
					},
				},
				{
					title: '开通时间',
					dataIndex: 'openTime',
					key: 'openTime',
					customRender(text, record) {
						if (text) {
							return moment(text).format('YYYY-MM-DD HH:mm:ss');
						}
						return '--';
					},
				},
				{
					title: '到期时间',
					dataIndex: 'validityPeriod',
					key: 'validityPeriod',
					scopedSlots: { customRender: 'validityPeriod' },
					customRender(text, record) {
						if (text) {
							return moment(text).format('YYYY-MM-DD HH:mm:ss');
						}
						return '--';
					},
				},
			],
			columns: [
				{
					title: '支付时间',
					dataIndex: 'addTime',
					key: 'addTime',
					customRender(text) {
						if (text) {
							return moment(text).format('YYYY-MM-DD HH:mm:ss');
						}
						return '--';
					},
				},
				{
					title: '支付方式',
					dataIndex: 'payType',
					key: 'payType',
					customRender(text) {
						return text == 1 ? '支付宝' : '微信';
					},
				},
				{
					title: '订单金额',
					dataIndex: 'totalAmount',
					key: 'totalAmount',
					customRender(text) {
						return text / 1000 + '元';
					},
				},
				{
					title: '实付金额',
					dataIndex: 'payAmount',
					key: 'payAmount',
					customRender(text) {
						return text / 1000 + '元';
					},
				},
				{
					title: '会员类型',
					dataIndex: 'level',
					key: 'level',
					customRender(text) {
						return text == 1 ? '月度会员' : text == 2 ? '季度会员' : text == 3 ? '半年会员' : text == 4 ? '年度会员' : '--';
					},
				},
			],
			userList: null,
			userCode: null,
			userId: null,
		};
	},
	methods: {
		moment(timeStap) {
			return this.$moment(timeStap).format('YYYY-MM-DD HH:mm:ss');
		},
		tableChange(e) {
			this.pagination.current = e.current;
		},
		getVipDetailInit(data, noVipDetal) {
			this.showVipDetail = true;
			this.noVipList = noVipDetal;
			this.loading = false;
			this.userCode = data.userCode;
			this.queryUserInfo();
		},
		getVipOrderList() {
			const _this = this;
			let params = {
				userCode: _this.userCode,
				state: 1,
			};
			this.$http
				.post('/viporder/queryList', params)
				.then(res => {
					_this.pageLoading = false;
					if (res.rescode == 200) {
						_this.vipList = res.data.dataList;
					} else {
						_this.$message.error('查询会员信息失败');
						_this.pageLoading = false;
					}
				})
				.catch(err => {
					_this.pageLoading = false;
					_this.$message.error('查询用户失败');
				});
		},
		queryUserInfo() {
			let _this = this;
			_this.$http
				.get('/userconsumer/query?userCode=' + this.userCode)
				.then(res => {
					_this.pageLoading = false;
					if (res.rescode == 200) {
						_this.userInfo = res.data;
						if (_this.userInfo.isVip === 1) {
							_this.getVipOrderList();
						}
					} else {
						_this.$message.error('查询用户失败');
					}
				})
				.catch(err => {
					_this.pageLoading = false;
					_this.$message.error('查询用户失败');
				});
			this.getVipOrderList();
			this.getPayVipDetail();
			this.getComboVipDetail();
		},
		getPayVipDetail() {
			const _this = this;
			let params = {
				userCode: _this.userCode,
			};
			_this.$http
				.post('/vipOpen/findMemberInfo', params)
				.then(res => {
					_this.pageLoading = false;
					if (res.rescode == 200) {
						_this.payVipInfo = res.data;
					} else {
						_this.$message.error('查询用户失败');
					}
				})
				.catch(err => {
					_this.pageLoading = false;
					_this.$message.error('查询用户失败');
				});
		},
		getComboVipDetail() {
			let _this = this;
			let params = {
				userCode: _this.userCode,
			};
			this.$http
				.post('/vipOpen/findComboInfo', params)
				.then(res => {
					_this.pageLoading = false;
					if (res.rescode == 200) {
						_this.comboInfo = res.data;
					} else {
						_this.pageLoading = false;
						_this.$message.error('查询用户失败');
					}
				})
				.catch(err => {
					_this.pageLoading = false;
					_this.$message.error('查询用户失败');
				});
		},
		detailSubmit() {
			this.showVipDetail = false;
		},
		onClose() {
			this.showVipDetail = false;
		},
	},
};
</script>

<style lang="less" scoped>
.box {
	.row {
		margin-top: 10px;
		padding-bottom: 10px;
		font-size: 18px;
		color: #000;

		.name {
			box-sizing: border-box;
			text-align: center;
		}
	}
}

.alert {
	font-size: 20px;
	text-align: center;
	margin-top: auto;
}

.submitBtn {
	text-align: center;
}
</style>
