<template>
    <a-modal class="illegalAdSubModal" title="上传头像" :width="400" :maskClosable="false" okText="提交" cancelText="关闭" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
        <a-upload :action="resourcesHost" list-type="picture-card" :before-upload="beforeImgUpload" :file-list="imgFileList" @preview="handlePreview" @change="handleImgChange">
            <div v-if="imgFileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                    上传图片
                </div>
            </div>
        </a-upload>
        <Vviewer ref="viewer"></Vviewer>
    </a-modal>
</template>

<script>
import Vviewer from "../../../components/Vviewer";
export default {
    components: {
        Vviewer,
    },
    props: ["visible", "closeModal", "filePath"],
    data() {
        return {
            confirmLoading: false,
            imgFileList: [],
            resourcesHost: "",
        };
    },
    created() {
        this.resourcesHost = process.env.VUE_APP_RESOURCES_API_HOST;
    },
    methods: {
        beforeImgUpload(file) {
            return new Promise((resolve, reject) => {
                var testmsg = /^image\/(jpeg|png|jpg|bmp|gif)$/.test(file.type);
                const isLt20M = file.size / 1024 / 1024 <= 20; //图片大小不超过2MB
                if (!testmsg) {
                    this.$message.error("仅支持图片!");
                    return reject(false);
                }
                if (!isLt20M) {
                    this.$message.error("上传图片大小不能超过20M!");
                    return reject(false);
                }
                return resolve(true);
            });
        },
        async handlePreview(file) {
            if (file.type.indexOf("image") != -1) {
                this.$refs.viewer.show([
                    {
                        thumbnail: process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath,
                        source: process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath,
                    },
                ]);
            } else if (file.type.indexOf("video") != -1) {
                this.mediaSrc = process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath;
                this.mediaVisible = true;
                this.mediaType = 2;
            } else if (file.type.indexOf("audio") != -1) {
                this.mediaSrc = process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath;
                this.mediaVisible = true;
                this.mediaType = 3;
            }
        },
        async handleImgChange({ file, fileList }) {
            if (file.status == "done") {
                fileList.map((item) => {
                    if (item.status == "done") {
                        if (item.response.data.coverImgUrl && item.response.data.coverImgUrl != "") {
                            item.thumbUrl = process.env.VUE_APP_RESOURCES_HOST + item.response.data.coverImgUrl;
                        }
                    }
                });
            }
            this.imgFileList = fileList;
        },
        handleCancel() {
            this.closeModal();
        },
        handleOk() {
            if (!this.imgFileList || this.imgFileList.length == 0) {
                this.$message.warning("请上传头像");
                return;
            }
            this.confirmLoading = true;
            setTimeout(() => {
                this.closeModal();
                this.confirmLoading = false;
                this.$emit("filePath", process.env.VUE_APP_RESOURCES_HOST + this.imgFileList[0].response.data.filePath);
            }, 1000);
        },
    },
};
</script>

<style lang="less" scoped>
.illegalAdSubModal {
    /deep/ .userPhoneSmsInput {
        width: 78%;
    }
    .formInput {
        margin-bottom: 8px;
    }
}
</style>
